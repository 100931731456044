import type { RouteLocationNormalized } from 'vue-router'
// stores/useSideNavStore.ts
import { acceptHMRUpdate, defineStore } from 'pinia'

interface TopLevelLink {
  name: string
  href: string
  icon: string
  enabled: boolean
  components: SubLink[]
}

interface SubLink {
  title: string
  href: string
  icon: string
  enabled: boolean
  beta?: boolean
}

export const useSideNavStore = defineStore('sideNav', () => {
  const isOpen = ref<boolean>(false)
  const isPinned = ref<boolean>(false)
  const activeLink = ref<TopLevelLink | null>(null)
  const topLevelLinks = ref<TopLevelLink[]>([])
  const showBetaItems = ref<boolean>(false)
  const sidebarVisible = ref<boolean>(true)

  const isActiveParentLink: ComputedRef<(href: string) => boolean> = computed(() => {
    return (href: string): boolean => {
      const route: RouteLocationNormalized = useRoute()
      return route?.path?.startsWith(href)
    }
  })

  function setTopLevelLinks(links: TopLevelLink[]): void {
    topLevelLinks.value = links
  }

  function toggleOpen(): void {
    isOpen.value = !isOpen.value
  }

  function togglePinned(): void {
    isPinned.value = !isPinned.value
  }

  function openSublinks(link: TopLevelLink): void {
    activeLink.value = link
    isOpen.value = true
  }

  function closeDrawer(): void {
    if (!isPinned.value) {
      isOpen.value = false
    }
  }

  function setShowBetaItems(value: boolean): void {
    showBetaItems.value = value
  }

  return {
    isOpen,
    isPinned,
    activeLink,
    topLevelLinks,
    showBetaItems,
    sidebarVisible,
    isActiveParentLink,
    setTopLevelLinks,
    toggleOpen,
    togglePinned,
    openSublinks,
    closeDrawer,
    setShowBetaItems,
  }
})

export type SideNavStore = ReturnType<typeof useSideNavStore>

// Enable HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSideNavStore, import.meta.hot))
}
